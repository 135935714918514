import {createReducer} from '@reduxjs/toolkit'
import {
  describeSeatingPlan,
  selectSeat,
  unselectSeat,
  selectSeats,
  selectPrice,
  selectLocation,
  setShowAccessibilityMode,
} from '../actions/seating'
import {SeatingState} from '../types'
import {checkout} from '../actions/checkout'
import {closeNativeModal} from '../actions/modals'

const defaultState: SeatingState = {
  plan: null,
  loading: false,
  selectedPlaces: [],
  selectedPrice: null,
  selectedLocation: null,
  filteredCategories: [],
  showAccessibilityMode: false,
}

export const seating = createReducer<SeatingState>(defaultState, builder => {
  builder
    .addCase(describeSeatingPlan.fulfilled, (state, action) => {
      const plan = action.payload.plan
      return {
        ...state,
        plan,
        filteredCategories: plan.categories,
      }
    })
    .addCase(selectPrice.fulfilled, (state, action) => ({
      ...state,
      selectedPrice: action.meta.arg,
      filteredCategories: action.payload,
    }))
    .addCase(selectLocation, (state, action) => ({...state, selectedLocation: action.payload}))
    .addCase(selectSeat, (state, action) => ({...state, selectedPlaces: [...state.selectedPlaces, action.payload]}))
    .addCase(selectSeats, (state, action) => ({
      ...state,
      selectedPlaces: [...state.selectedPlaces, ...action.payload],
      showAccessibilityMode: false,
    }))
    .addCase(unselectSeat, (state, action) => {
      const selectedPlaces = [...state.selectedPlaces]
      const index = selectedPlaces.findIndex(seat => seat.id === action.payload)

      selectedPlaces.splice(index, 1)

      return {
        ...state,
        selectedPlaces,
      }
    })
    .addCase(checkout.pending, state => ({...state, loading: true}))
    .addCase(setShowAccessibilityMode, (state, action) => ({...state, showAccessibilityMode: action.payload}))
    .addCase(closeNativeModal, state => ({...state, loading: false}))
})
